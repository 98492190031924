<template>
  <v-card class="pa-4 guide4Step5">
    <div
      class="d-flex align-center"
    >
      <div class="text-h3 font-weight-bold">
        {{ $t('addNewPrinter') }}
      </div>
      <v-spacer/>
      <v-btn
        elevation="0"
        icon
        @click="cancel"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <template v-if="step===0">
      <div class="text-subtitle-1 mt-4">
        {{ $t('choosePrinterType') }}
      </div>

      <div
        class="mt-4"
        style="
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
        grid-gap: 24px
"
      >
        <v-card
          v-for="item in supportPrinters"
          :key="item.id"
          :dark="item.printerTypeId===8"
          class="pa-4 d-flex align-center justify-center flex-column"
          outlined
          @click="printerTypeChose(item)"
        >
          <v-card
            elevation="0"
          >
            <printer-image
              :height="160"
              :printer-type-id="item.printerTypeId"
            />
          </v-card>
          <div
            class="mt-4 text-body-1"
          >
            {{ item.name }}
          </div>
        </v-card>
      </div>
    </template>
    <template v-if="step===1">
      <div class="text-subtitle-1 mt-4">
        {{ $t('choosePrinterConnection') }}
      </div>
      <div
        class="mt-4"
        style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 24px"
      >
        <v-card
          class="pa-2 text-center"
          outlined
          @click="chooseInterface('usb')"
        >
          <v-card
            class="d-flex align-center justify-center"
            elevation="0"
            height="96px"
          >
            <v-icon size="48">
              mdi-usb
            </v-icon>
          </v-card>
          <div class="mt-4">
            USB
          </div>
        </v-card>
        <v-card
          class="pa-2 text-center"
          outlined
          @click="chooseInterface('lan')"
        >
          <v-card
            class="d-flex align-center justify-center"
            elevation="0"
            height="96px"
          >
            <v-icon size="48">
              mdi-lan
            </v-icon>
          </v-card>
          <div class="mt-4">
            {{ $t('ethernetCable') }}
          </div>
        </v-card>
      </div>
    </template>
    <template v-if="step===2">
      <div v-if="checkingExistence">
        <div class="text-subtitle-1 my-4">
          {{ $t('checkingWhetherThePrinterExists') }}
        </div>
      </div>
      <div v-else>
        <div class="text-subtitle-1 mt-4">
          <p>{{ $t('inputStep2Title', { step2Title: step2Title }) }}</p>
        </div>
        <div class="text-body-2 my-4">
          <div
            v-if="selectedInterface==='usb'"
          >
            {{ $t('checkEquipmentList') }}
          </div>

          <div
            v-else
          >
            {{ $t('clickFeedButton') }}
          </div>
        </div>
      </div>

      <v-text-field
        v-model="deviceSN"
        :error-messages="snIsExist?$t('PrintSerialIsAllreadyUsed'):''"
        :placeholder="step2Title"
        :rules="step2Rule"
        autofocus
        outlined
        @keydown.enter="setSn"
      />

      <v-btn
        :disabled="snIsExist"
        :loading="checkingExistence"
        block
        color="primary"
        elevation="0"
        @click="setSn"
      >
        {{ $t('save') }}
      </v-btn>
    </template>

    <template v-if="step===3">
      <template v-if="isSticker">
        <div class="text-subtitle-1 mt-4">
          {{ $t('labelSizeSetting') }}
        </div>

        <v-card
          class="mt-4 pa-2"
          outlined
        >
          <div class="text-subtitle-1">
            {{ $t('Hint') }}
          </div>
          <div class="text--secondary text-body-2">
            <p v-html="$t('labelPaperRule')"/>
          </div>
        </v-card>
        <div class="mt-4">
          <div class="d-flex">
            <div>
              <div class="text-overline">
                {{ $t('paperLong') }}
              </div>
              <v-text-field
                v-model="stickHeight"
                :placeholder="$t('unitMM')"
                dense
                hide-details
                outlined
                type="number"
              >
                <template #append>
                  <div style="margin-top: 2px">
                    mm
                  </div>
                </template>
              </v-text-field>
            </div>
            <div style="width: 16px"/>
            <div>
              <div class="text-overline">
                {{ $t('paperHigh') }}
              </div>
              <v-text-field
                v-model="stickWidth"
                :placeholder="$t('unitMM')"
                dense
                hide-details
                outlined
                type="number"
              >
                <template #append>
                  <div style="margin-top: 2px">
                    mm
                  </div>
                </template>
              </v-text-field>
            </div>
          </div>

          <div class="mt-4">
            <div class="text-overline">
              {{ $t('paperSpacing') }}
            </div>
            <v-text-field
              v-model="stickGap"
              :placeholder="$t('unitMM')"
              dense
              hide-details
              outlined
              type="number"
            >
              <template #append>
                <div style="margin-top: 2px">
                  mm
                </div>
              </template>
            </v-text-field>
          </div>
          <div style="width: 16px"/>
        </div>
      </template>
      <template v-else-if="isFeie">
        <div class="text-subtitle-1 mt-4 mb-4">
          {{ $t('EnterDeviceSN') }}
        </div>
        <v-text-field
          v-model="deviceSN"
          autofocus
          :label="$t('DeviceSN')"
          outlined
        />
        <v-text-field
          v-model="deviceKey"
          :label="$t('DeviceKey')"
          outlined
        />
      </template>

      <template v-else>
        <div class="text-subtitle-1 mt-4">
          {{ $t('ConfigOutputPaperSize') }}
        </div>
        <v-card
          class="my-4 pa-2 "
          outlined
        >
          <div class="text-subtitle-1">
            {{ $t('instructions') }}
          </div>
          <div class="text--secondary text-body-2">
            <p v-html="$t('thermalPaperRule')"/>
          </div>
        </v-card>
        <v-select
          v-model="thermalWidth"
          :items="thermalPaperSize"
          :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
          :placeholder="$t('unitMM')"
          dense
          hide-details
          outlined
        >
          <template #append>
            <div style="margin-top: 2px">
              mm
            </div>
          </template>
        </v-select>
      </template>

      <div
        v-if="!isFeie"
        class="mt-4"
      >
        <v-checkbox
          v-model="isThermalPaper"
          :label="$t('determineThePaper')"
          class="ml-n2"
          dense
          hide-details
        />
      </div>
      <div class="mt-4">
        <v-btn
          :disabled="!isThermalPaper&&!isFeie"
          block
          color="primary lighten-4 black--text"
          elevation="0"
          @click="handlePaperSize"
        >
          {{ $t('nextStep') }}
          <v-icon right>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </div>
    </template>

    <template v-if="step===4">
      <v-form>
        <div class="text-overline mt-8">
          {{ $t('printerName') }}<span class="red--text">*</span>
        </div>
        <v-text-field
          v-model="devicePrinterName"
          autofocus
          clearable
          hide-details
          outlined
        />
        <div class="mt-4">
          <div class="mt-2">
            <v-checkbox
              v-model="isSingleLinePrint"
              :label="$t('AnyOtherNeedsBeCut')"
              class="ml-n2"
              dense
              hide-details
            />
          </div>
          <div class="mt-2">
            <v-checkbox
              v-model="upsideDown"
              :label="$t('printUpsideDown')"
              class="ml-n2"
              dense
              hide-details
            />
          </div>

          <div class="mt-4">
            <v-btn
              :disabled="!devicePrinterName"
              block
              color="primary"
              elevation="0"
              @click="savePrinter"
            >
              <v-icon left>
                mdi-save
              </v-icon>
              {{ $t('save') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </template>
    <template v-if="step===5">
      <div class="text-subtitle-1 mt-4">
        🎉 {{ $t('addSuccess') }}
      </div>
    </template>
  </v-card>
</template>

<script>
  import { Rules, showSuccessMessage } from '@/common/utils'
  import IKUtils from 'innerken-js-utils'
  import DeviceList from '@/model/print/DeviceList'
  import PrinterGroup from '@/model/print/PrinterGroup'
  import i18n from '@/i18n'
  import PrinterImage from '@/views/AadenPages/print/composable/PrinterImage.vue'

  const printerTypes = [
    {
      name: i18n.t('receiptsPrinter'),
      printerTypeId: 1,
      id: 1,
    },
    {
      name: i18n.t('labelPrinter'),
      printerTypeId: 9,
      id: 2,
    },
    {
      name: i18n.t('wifi'),
      printerTypeId: 2,
      id: 4,
    },
    {
      name: 'Sunmi Printer',
      printerTypeId: 8,
      id: 3,
    },
  ]
  export default {
    name: 'AddNewDeviceDialog',
    components: { PrinterImage },
    props: {
      onlyDeviceMode: {},
    },
    data: function () {
      return {
        printerGroupReceptionId: 3, // 前台打印分组Id
        thermalPaperSize: ['80', '55'],

        step: 0,
        selectedPrinterType: null,
        selectedInterface: null,

        deviceSN: '',
        deviceKey: '',
        devicePrinterName: '',
        checkingExistence: false,
        Rules: Rules,
        availablePrinterConnections: ['usb', 'network'],
        loading: false,

        isSingleLinePrint: true,
        isSingleOrderPrint: false,

        upsideDown: false,

        thermalWidth: '80',
        stickHeight: '40',
        stickWidth: '60',
        stickGap: '3',

        isThermalPaper: false,
        deviceList: [],
      }
    },
    computed: {
      supportPrinters () {
        const alreadyHaveASunmiPrinter = this.deviceList.some(it => it.printerTypeId === 8)
        return printerTypes.filter(it => !alreadyHaveASunmiPrinter || it.printerTypeId !== 8)
      },
      isUsb () {
        return this.selectedInterface === 'usb'
      },
      step2Title () {
        return this.isUsb ? i18n.t('USBDeviceName') : i18n.t('networkCableDeviceIp')
      },
      step2Rule () {
        return [this.isUsb ? Rules.required : Rules.ip && Rules.required]
      },
      isSticker () {
        return this.selectedPrinterType?.id === 2
      },
      isFeie () {
        return this.selectedPrinterType?.id === 4
      },
      isSunmi () {
        return this.selectedPrinterType?.id === 3
      },

      printerTypeId () {
        if (this.isSunmi) {
          return '8'
        } else if (this.isFeie) {
          return '2'
        } else if (this.isSticker) {
          return this.isUsb ? '9' : '10'
        } else {
          return this.isUsb ? '6' : '7'
        }
      },

      snIsExist () {
        return this.deviceList.map(item => item.sn).includes(this.deviceSN)
      },
    },
    async mounted () {
      this.deviceList = await DeviceList.getList()
    },

    methods: {
      printerTypeChose (type) {
        this.selectedPrinterType = type
        if (this.selectedPrinterType.id === 3) {
          this.step = 4
          this.devicePrinterName = 'Sunmi'
        } else if (this.isFeie) {
          this.step = 3
        } else {
          this.step++
        }
      },
      chooseInterface (type) {
        this.selectedInterface = type
        this.step++
        if (this.isUsb) {
          this.deviceSN = '/dev/usb/lp'
        } else {
          this.deviceSN = '192.168.'
        }
      },
      restart () {
        this.step = 0
        this.deviceSN = ''
        this.isThermalPaper = false
      },
      async setSn () {
        this.checkingExistence = true
        this.step++
        this.checkingExistence = false
      },
      async saveDevice (printerGroupId) {
        const printerData = {
          id: -1,
          sn: this.deviceSN || 'Sunmi',
          printerName: this.devicePrinterName,
          printerTypeId: this.printerTypeId,
          identity: 'AADEN-PRINTER',
          key: this.deviceKey || 'Aaden',
          groupId: [printerGroupId].flat(),
          sectionIds: [],
          height: this.stickHeight,
          width: this.isSticker ? this.stickWidth : this.thermalWidth,
          gap: this.stickGap,
        }

        await DeviceList.add(printerData)
      },

      async savePrinter () {
        await this.actionAnd(async () => {
          if (!this.onlyDeviceMode) {
            const printerGroupData = {
              name: this.devicePrinterName,
              isSingleOrderPrint: 0,
              isSingleLinePrint: this.isSingleLinePrint ? 1 : 0,
              upsideDown: this.upsideDown ? 1 : 0,
            }

            const printerGroupId = await PrinterGroup.add(printerGroupData)
            await this.saveDevice(printerGroupId)
            this.restart()
            this.$emit('reload')
          } else {
            await this.saveDevice([])
            this.restart()
            this.$emit('device-created')
          }
        })
      },
      async actionAnd (action) {
        this.loading = true
        try {
          await action()
          this.$emit('reload', true)
          showSuccessMessage()
        } catch (e) {
        }
        this.loading = false
        this.deviceSN = null
      },
      validate () {
        this.$refs.form.validate()
      },
      cancel () {
        this.$emit('cancle')
        this.restart()
      },
      handlePaperSize () {
        if (this.isThermalPaper || this.isFeie) {
          this.step++
        } else {
          IKUtils.showConfirm(i18n.t('ThermalPrinterPaper'), '')
        }
      },
    },
  }
</script>

<style scoped>

</style>
