import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import PrinterGroup from './PrinterGroup'
import PrinterType from './PrinterType'
import Section from '../Section'
import dayjs from 'dayjs'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'deviceId',
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredNew: false,
    },
    form: false,
  },
  printerName: {
    displayName: 'printerName',
  },
  printerTypeId: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: PrinterType.getList,
        itemText: 'typeName',
      },
      requiredEdit: false,
    },
    displayName: 'printerTypeName',
  },
  identity: {
    displayName: 'identity',
    header: false,
    formConfig: {
      inEdit: false,
    },
    form: false,
  },
  sn: {
    displayName: 'sn',
  },
  key: {
    displayName: 'key',
    formConfig: {
      default: dayjs().toISOString(),
      inEdit: false,
    },
    header: false,
  },
  groupId: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: PrinterGroup.getList,
        itemText: 'name',
        multiple: true,
      },
      required: false,
    },
    displayName: 'groupSecName',
  },
  sectionIds: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: Section.getList,
        itemText: 'name',
        multiple: true,
      },
      required: false,
    },
    displayName: 'sectionName',
    header: false,
  },

}
const config = {
  load: async function (filter) {
    const data = await IKDataEntity.generalLoad('Printer.php', {
      op: 'showAllPrinterStatus',
      ...filter,
    })
    return data.content
  },
  add: function (item) {
    item.groupIds = JSON.stringify(item.groupId)
    item.name = item.printerName
    item.printerSectionID = JSON.stringify(item.sectionIds)

    return hillo.postWithUploadFile('Printer.php?op=add', item, {
      showLoading: false,
    })
  },
  edit: function (item) {
    item.groupIds = JSON.stringify(item.groupId)
    item.name = item.printerName
    item.printerSectionID = JSON.stringify(item.sectionIds)

    return hillo.postWithUploadFile('Printer.php?op=edit', item, {
      showLoading: false,
    })
  },
  remove: function (id) {
    return hillo.post('Printer.php?op=remove', { id: id })
  },
}

export default IKDataEntity.ModelFactory(entity, config)
