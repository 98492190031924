<template>
  <div>
    <v-card
      :dark="hasPrinter&&isSunmiPrinter"
      class="pa-4"
      elevation="0"
      height="356"
      style="position: relative;"
    >
      <div
        v-if="hasPrinter&&!isSunmiPrinter"
        style="position: absolute;top: 16px;left: 0;right: 0;
        width: fit-content;margin: auto"
      >
        <v-icon
          :color="(printerInfo?.status === 1 || printerInfo?.printerTypeId === 2) ? 'success' : 'error'"
        >
          mdi-circle-medium
        </v-icon>
        <span
          class="text-caption"
        >
          {{ (printerInfo.status === 1 || printerInfo.printerTypeId === 2) ? $t('normal') : $t('unnormal') }}
        </span>
      </div>
      <div class="d-flex align-center">
        <v-spacer/>
        <div style="display: flex; text-align: center">
          <v-menu
            close-on-content-click
            left
            offset-overflow
            offset-y
            rounded
          >
            <template #activator="{on, attrs}">
              <v-icon
                icon
                style="border-radius: 12px"
                v-bind="attrs"
                v-on="on"
              >
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-list
              outlined
              width="200px"
            >
              <v-list-item @click="editPrinter">
                {{ $t('edit') }}
              </v-list-item>
              <v-list-item @click="deletePrinter">
                {{ $t('delete') }}
              </v-list-item>
              <v-list-item
                v-if="isStickerPrinter"
                @click="testPrinter"
              >
                {{ $t('switchAndTestPaperSize') }}
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-card
        class="my-2"
        elevation="0"
      >
        <template v-if="hasPrinter">
          <printer-display :printer="printerInfo"/>
          <div class="d-flex flex-column align-center justify-center text-body-2">
            <div
              class="text-body-1 textNoWrap font-weight-bold"
            >
              <div>
                {{ printerGroup.name }}
              </div>
            </div>
            <div class="text-caption mt-1">
              <span
                v-if="isSunmiPrinter"
                class="warning--text font-weight-bold"
              >
                {{ $t('SunmiPrinter') }}
              </span>
              <span v-else-if="isStickerPrinter">
                {{ printerInfo.height }}x{{ printerInfo.width }} (mm) | {{ printerInfo.gap }} (mm)
              </span>
              <span v-else>
                {{ printerInfo.width }} (mm)
              </span>
            </div>
            <div
              v-if="!isSunmiPrinter"
              class="text-caption"
            >
              <span>
                {{ printerInfo.sn }}
              </span>
            </div>
            <div
              class="textNoWrap mt-1"
              style="display: flex; text-align: center"
            >
              {{ $t('printerCount', { printerCount: printerGroup.totalDishesCount }) }}
            </div>
          </div>
        </template>
        <template
          v-else
        >
          <v-card
            class="d-flex align-center justify-center"
            flat
            height="196"
          >
            <v-card
              class="px-8 py-4 text-center"
              flat
            >
              <v-icon size="60">
                mdi-printer-off
              </v-icon>
            </v-card>
          </v-card>
          <div
            class="d-flex align-center justify-center
          flex-column text-body-2"
          >
            <div
              class="text-body-1 textNoWrap font-weight-bold"
            >
              {{ printerGroup.name }}
            </div>
            <div
              class="textNoWrap mt-1"
              style="display: flex; text-align: center"
            >
              {{ $t('printerCount', { printerCount: printerGroup.totalDishesCount }) }}
            </div>
            <v-btn
              v-if="printerGroup.id!==10"
              class="mt-4"
              color="primary"
              depressed
              small
              text
              @click="addPrinter"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              {{ $t('CreateNewPrinter') }}
            </v-btn>
          </div>
        </template>
      </v-card>
    </v-card>
  </div>
</template>

<script>
  import IKUtils from 'innerken-js-utils'
  import { printerTest } from '@/common/deviceTest'
  import { printerIsStickerPrinter } from '@/model/print/deviceManage/managedDevice'
  import PrinterDisplay from '@/views/AadenPages/print/composable/PrinterDisplay.vue'

  export default {
    name: 'DeviceCard',
    components: { PrinterDisplay },
    props: {
      printerGroup: {
        type: Object,
        default: () => {
        },
      },
    },
    computed: {
      printerInfo () {
        return this.printerGroup.bindedPrinter
      },
      isSunmiPrinter () {
        return this.printerInfo?.printerTypeId === 8
      },
      isStickerPrinter () {
        return printerIsStickerPrinter(this.printerInfo?.printerTypeId)
      },
      noPrinter () {
        return !this.printerInfo
      },
      hasPrinter () {
        return !this.noPrinter
      },

    },
    mounted () {
    },
    methods: {

      addPrinter () {
        this.$emit('add')
      },
      editPrinter () {
        this.$emit('edit')
      },

      testPrinter () {
        this.$emit('test')
      },
      deletePrinter () {
        this.$emit('delete')
      },

      async testPrint (printGroupId, testCount) {
        await printerTest(printGroupId, testCount)
        IKUtils.toast()
      },

    },
  }
</script>

<style scoped>

.textNoWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
