<template>
  <v-card>
    <div class="d-flex align-center pa-4">
      <div class="text-h3 font-weight-bold">
        {{ $t('deleteThisPrinter') }}
      </div>
    </div>
    <div class="pa-4">
      {{ $t('moveDishesToPrinter', { dishesLength: selectedPrinterGroup.totalDishesCount }) }}
    </div>
    <div class="pa-4">
      <v-item-group v-model="targetPrinterIndex">
        <div
          style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));grid-gap: 8px"
        >
          <v-item
            v-for="x in selectablePrinterGroup"
            :key="x.id"
            v-slot="{ active, toggle }"
          >
            <v-card
              class="pa-2 d-flex align-center justify-center"
              elevation="0"
              outlined
              @click="toggle()"
            >
              <v-responsive
                :aspect-ratio="1"
              >
                <div
                  class="d-flex align-center justify-center flex-column"
                  style="height: 100%;position: relative"
                >
                  <v-icon
                    v-if="active"
                    color="primary"
                    size="36"
                    style="position: absolute;bottom: 8px;right: 8px;"
                  >
                    mdi-check
                  </v-icon>
                  <div>
                    {{ x.name }}
                  </div>
                </div>
              </v-responsive>
            </v-card>
          </v-item>
        </div>
      </v-item-group>
    </div>
    <div class="pa-4">
      <v-btn
        :loading="loading"
        outlined
        class="mr-2"
        @click="$emit('close')"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        :loading="loading"
        elevation="0"
        class="primary lighten-4 black--text"
        :disabled="targetPrinterIndex==null"
        @click="moveDishAndDeletePrinter"
      >
        {{ $t('save') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
  import Dish from '../../../../model/dish/Dish'
  import { mapMutations } from 'vuex'
  import i18n from '@/i18n'

  export default {
    name: 'DeletePrinterDialog',
    props: {
      selectedPrinterGroup: {},
      selectablePrinterGroup: {},
    },
    data () {
      return {
        targetPrinterIndex: null,
        loading: false,
      }
    },
    mounted () {
      this.targetPrinterIndex = null
    },
    methods: {
      ...mapMutations(['showErrorDialog']),
      async moveDishAndDeletePrinter () {
        this.loading = true
        const dishesList = (await Dish.getList(true)).filter(it => it.printGroupId === this.selectedPrinterGroup.id)
        for (const item of dishesList) {
          item.printGroupId = this.selectablePrinterGroup[this.targetPrinterIndex].id
          const res = await Dish.edit(item)
          if (res.content === 'isUsing') {
            this.showErrorDialog(i18n.t('ArticleInActiveOrder'))
          }
        }
        this.loading = false
        this.$emit('delete', this.selectedPrinterGroup)
        this.$emit('close')
      },
    },
  }
</script>

<style scoped>

</style>
