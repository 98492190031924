import { render, staticRenderFns } from "./PrinterDisplay.vue?vue&type=template&id=31c22cb9&scoped=true&"
import script from "./PrinterDisplay.vue?vue&type=script&lang=js&"
export * from "./PrinterDisplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c22cb9",
  null
  
)

export default component.exports