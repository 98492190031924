<template>
  <div>
    <v-progress-linear
      v-if="mainPageLoading"
      color="primary"
      indeterminate
    />
    <page-header class="mb-4">
      {{ $t('printer') }}
      <template #subtitle>
        {{ $t('printerTitleHin') }}
      </template>
    </page-header>

    <div
      :style="!$vuetify.breakpoint.lgAndUp ? 'grid-template-columns: repeat(2,minmax(0,1fr));' : 'grid-template-columns: repeat(4,minmax(0,1fr));'"
      style="display: grid;grid-gap: 12px"
    >
      <v-card
        class="guide4Step4 pa-4 d-flex align-center justify-center"
        color="#eee"
        elevation="0"
        height="356"
        @click="createNewPrinter()"
      >
        <div class="text-center">
          <v-icon size="48">
            mdi-plus
          </v-icon>
          <div>
            {{ $t('addNewPrinter') }}
          </div>
        </div>
      </v-card>
      <front-group-device-card
        v-if="!mainPageLoading"
        @add="addPrinterToFrontGroup"
      />

      <template
        v-for="(pg,index) in printerGroupList"
      >
        <device-card
          v-if="!mainPageLoading"
          :key="'normalPrinterWithID' + index"
          :printer-group="pg"
          @add="asyncAddPrinterToGroup(pg.id)"
          @delete="deleteDevice(pg)"
          @edit="editDevice(pg)"
          @test="testDevice(pg)"
        />
      </template>
    </div>

    <v-dialog
      v-model="createDeviceDialog"
      max-width="600px"
      persistent
    >
      <add-new-device-dialog
        v-if="createDeviceDialog"
        :only-device-mode="onlyCreateDeviceMode"
        @cancle="closeAddPrinter"
        @reload="loadData"
        @device-created="deviceCreated"
      />
    </v-dialog>

    <change-size-and-test-dialog
      v-model="showChangeAndTestDialog"
      :printer="selectedPrinter?.bindedPrinter"
      @need-refresh="loadData"
    />
    <v-dialog
      v-model="deletePrinterDialog"
      max-width="600px"
    >
      <delete-printer-dialog
        :selectable-printer-group="selectablePG"
        :selected-printer-group="selectedPrinterGroup"
        @close="closeDeleteDialog"
        @delete="realDeletePrinter"
      />
    </v-dialog>
    <v-dialog
      v-model="showChooseDeviceDialog"
      max-width="600px"
    >
      <v-card
        class="pa-6"
        color="#f6f6f6"
      >
        <div class="text-h4">
          {{ $t('ChoosePrintingDevice') }}
        </div>
        <div class="text-caption">
          {{ $t('SelectAPrintingDeviceOrCreateNewOne') }}
        </div>
        <div class="mt-4">
          <div
            style="display: grid;
          grid-template-columns: repeat(auto-fit,minmax(150px,1fr));
          grid-gap: 16px;width: 100%"
          >
            <v-card
              class="pa-4 d-flex flex-column justify-center align-center"
              elevation="0"
              @click="selectCallback(-1)"
            >
              <printer-image
                :printer-type-id="-1"
              />
              <v-spacer/>
              <div class="text-body-2">
                {{ $t('CreateNewPrinter') }}
              </div>
            </v-card>
            <v-card
              v-for="d in deviceList"
              :key="d.id"
              :dark="d.printerTypeId===8"
              class="pa-4 flex-column d-flex justify-center align-center"
              elevation="0"
              @click="selectCallback(d)"
            >
              <printer-image :printer-type-id="d.printerTypeId"/>
              <div class="text-body-2">
                {{ d.name }}
              </div>
              <div class="text-body-2">
                {{ d.sn }}
              </div>
            </v-card>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <edit-dialog
      v-model="showEditDialog"
      :printer="editingPrinter"
      @close="loadData"
    />
  </div>
</template>

<script>


  import hillo from 'hillo'
  import AddNewDeviceDialog from '@/views/AadenPages/print/fragment/AddNewDeviceDialog'
  import DeviceCard from '@/views/AadenPages/print/composable/DeviceCard.vue'
  import PageHeader from '@/composable/InK/typography/PageHeader'
  import EditDialog from './fragment/EditDialog'
  import ChangeSizeAndTestDialog from '@/views/AadenPages/print/fragment/ChangeSizeAndTestDialog'
  import DeletePrinterDialog from './fragment/DeletePrinterDialog'
  import { loadDevices, printerIsStickerPrinter } from '@/model/print/deviceManage/managedDevice'
  import FrontGroupDeviceCard from '@/views/AadenPages/print/fragment/deviceDisplay/FrontGroupDeviceCard.vue'
  import DeviceList from '@/model/print/DeviceList'
  import PrinterImage from '@/views/AadenPages/print/composable/PrinterImage.vue'
  import Promise from 'lodash-es/_Promise'

  export default {
    components: {
      PrinterImage,
      FrontGroupDeviceCard,
      DeletePrinterDialog,
      ChangeSizeAndTestDialog,
      EditDialog,
      PageHeader,
      DeviceCard,
      AddNewDeviceDialog,
    },
    data: function () {
      return {
        printerGroupList: [],
        selectablePG: [],
        deviceList: [],
        selectedPrinterGroup: null,

        mainPageLoading: false,

        deletePrinterDialog: null,
        createDeviceDialog: false,
        onlyCreateDeviceMode: false,
        showChangeAndTestDialog: false,
        showChooseDeviceDialog: false,
        selectCallback: null,
        selectedPrinter: null,

        showEditDialog: null,
        editingPrinter: null,

      }
    },
    async mounted () {
      await this.loadData()
    },
    methods: {
      async closeDeleteDialog () {
        this.deletePrinterDialog = false
        await this.loadData()
      },
      async loadData () {
        this.mainPageLoading = true
        this.printerGroupList = await loadDevices()
        console.log(this.printerGroupList, 'list')
        this.createDeviceDialog = false
        this.showEditDialog = false
        this.showChangeAndTestDialog = false
        this.mainPageLoading = false
      },

      createNewPrinter (onlyDevice = false) {
        this.createDeviceDialog = true
        this.onlyCreateDeviceMode = onlyDevice
      },

      closeAddPrinter () {
        this.createDeviceDialog = false
        this.selectedPrinter = null
      },

      editDevice (printer) {
        this.editingPrinter = printer
        this.showEditDialog = true
      },

      testDevice (printer) {
        this.showChangeAndTestDialog = true
        this.selectedPrinter = printer
      },
      async choosePrinter () {
        this.deviceList = (await DeviceList.getList(true))
          .filter(it => !printerIsStickerPrinter(it.printerTypeId))
        return new Promise(resolve => {
          this.showChooseDeviceDialog = true
          this.selectCallback = (t) => {
            if (t === -1) {
              this.createNewPrinter(true)
            } else {
              this.showChooseDeviceDialog = false
              resolve(t)
            }
          }
        })
      },

      async realDeletePrinter (printerGroup) {
        this.deletePrinterDialog = false
        if (printerGroup.bindedPrinter) {
          await hillo.post('Printer.php?op=remove', { id: printerGroup.bindedPrinter.id })
        }
        await hillo.post('Printer.php?op=deletePrinterGroup', { id: printerGroup.id })
        await this.loadData()
      },
      deviceCreated () {
        this.createDeviceDialog = false
        setTimeout(() => {
          this.addPrinterToFrontGroup()
        }, 1000)
      },

      async asyncAddPrinterToGroup (id) {
        const device = await this.choosePrinter()
        device.groupId = [device.groupId, id].flat()
        await DeviceList.edit(device)
        console.log(device)
        await this.loadData()
      },

      async addPrinterToFrontGroup () {
        const device = await this.choosePrinter()
        for (const device of this.deviceList) {
          device.groupId = device.groupId.filter(it => it !== 3)
          await DeviceList.edit(device)
        }
        device.groupId = [device.groupId, 3].flat()
        await DeviceList.edit(device)
        console.log(device)
        await this.loadData()
      },

      async deleteDevice (printerGroup) {
        this.selectedPrinterGroup = printerGroup
        this.deletePrinterDialog = true
        this.selectablePG = this.printerGroupList.filter(item => item.id !== printerGroup.id)
        if (printerGroup.totalDishesCount === '0') {
          await this.realDeletePrinter(printerGroup)
        }
      },

    },
  }
</script>
