<template>
  <v-navigation-drawer
    v-model="realDialog"
    :width="$vuetify.breakpoint.smAndDown ? '100%' : width"
    style="z-index: 10 !important;"
    :style="$vuetify.breakpoint.smAndDown ? 'left: 0;' : ''"
    fixed
    right
    temporary
    touchless
  >
    <v-container
      v-if="printer"
      class="pa-6"
      style="background-color: #F9FBFC"
    >
      <div class="d-flex align-center mb-8">
        <back-button @close-event="$emit('close')"/>
        <div class="text-h3 font-weight-bold">
          {{ editedIndex === -1 ? $t('new') : $t('edit') + '/' }}{{ printer?.name ?? '' }}
        </div>
        <v-spacer/>
      </div>


      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <div
          class="pa-6 rounded-xl"
          style="border: 1px solid #E0E0E0; background-color: white"
        >
          <div>
            <div>{{ $t('printerName') }}<span style="color: red">*</span></div>
            <v-text-field
              v-model="localPrinter.name"
              :rules="rules.name"
              outlined
            />
          </div>

          <div>
            <div>{{ $t('cutOrderEachItem') }}<span style="color: red">*</span></div>
            <v-select
              v-model="localPrinter.isSingleOrderPrint"
              :items="item"
              :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
              item-text="text"
              item-value="value"
              outlined
            />
          </div>

          <div>
            <div>{{ $t('ob_upSideDown') }}<span style="color: red">*</span></div>
            <v-select
              v-model="localPrinter.upsideDown"
              :items="item"
              :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
              item-text="text"
              item-value="value"
              outlined
            />
          </div>

          <div>
            <div>{{ $t('auto_repeat_times') }}<span style="color: red">*</span></div>
            <v-text-field
              v-model="localPrinter.defaultRetryCount"
              :rules="rules.count"
              outlined
            />
          </div>

          <div>
            <div>{{ $t('sn') }}<span style="color: red">*</span></div>
            <div class="text-caption text--secondary">
              {{ $t('snHint') }}
            </div>
            <v-text-field
              v-model="localPrinter.sn"
              :rules="rules.sn"
              outlined
            />
          </div>
        </div>

        <div class="mt-8 pl-1">
          <template v-if="$vuetify.breakpoint.lgAndUp">
            <v-btn
              :disabled="!valid"
              :loading="loading"
              class="mr-4"
              color="primary"
              elevation="0"
              @click="save"
            >
              {{ $t('save_change') }}
            </v-btn>
          </template>
          <template v-else>
            <fab-button
              fab-fixed
              color="white"
              :fab-disabled="!valid"
              :fab-loading="loading"
              fab-bottom
              fab-right
              icon="mdi-content-save"
              background="green"
              @click-event="save"
            />
          </template>
        </div>
      </v-form>
    </v-container>
  </v-navigation-drawer>
</template>

<script>

  import IKUtils from 'innerken-js-utils'
  import PrinterGroup from '../../../../model/print/PrinterGroup'
  import Printer from '../../../../model/print/Printer'
  import FabButton from '@/views/AadenPages/components/FabButton/FabButton'
  import BackButton from '@/composable/InK/BackButton'

  export default {
    name: 'EditDialog',
    components: {
      FabButton,
      BackButton,
    },
    props: {

      value: {
        default: null,
        type: Boolean,
      },
      width: {
        default: 'min(700px, calc(100vw - 300px))',
        type: String,
      },
      printer: {
        type: Object,
        default: () => {
        },
      },
    },

    data () {
      return {
        printerGroupReceptionId: 3, // 前台打印分组Id
        realDialog: {},
        valid: true,
        loading: false,
        editedItem: false,
        item: [{
          text: this.$t('no'),
          value: false,
        }, {
          text: this.$t('yes'),
          value: true,
        }],
        widthItems: ['80', '50', '44'],
        rules: {
          name: [v => !!v || 'Item is required'],
          sn: [v => !!v || 'Item is required'],
          count: [v => !!v || 'Item is required'],
          size: [v => !!v || 'Item is required'],
        },
        localPrinter: { name: '' },
        editedIndex: null,
      }
    },

    computed: {
      isNormalPrinter () {
        return this.localPrinter.isPrinter && !this.localPrinter.isStickerPrinter
      },
    },

    watch: {
      value: {
        immediate: true,
        handler (val) {
          this.realDialog = val
          this.localPrinter.sn = this.printer?.bindedPrinter?.sn
          this.localPrinter = Object.assign(this.localPrinter, this.printer)
        },
      },
      realDialog (val) {
        if (!val) {
          this.$emit('input', val)
        }
      },
    },
    methods: {
      close (needRefresh = false, args) {
        this.realDialog = false
        this.$emit('close', args)
      },
      async wait (action) {
        this.loading = true
        try {
          await action()
        } catch (e) {
        }
        this.loading = false
      },
      async save (close = true) {
        if (!this.$refs.form.validate()) {
          return
        }

        await this.wait(async () => {
          if (this.editedIndex > -1) {
            // save printerGroup

            await PrinterGroup.edit(this.localPrinter, false)

            // save printer
            const printer = Object.assign({}, this.localPrinter.bindedPrinter, {
              sn: this.localPrinter.sn,
            })
            printer.printerName = this.localPrinter.name
            await Printer.edit(printer, false)

            IKUtils.toast(this.$t('edit_success'))

            this.close()
          }
        })
      },

    },

  }
</script>

<style scoped>

</style>
