import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
  },
  isSticker: {
    displayName: 'isSticker',
  },
  lineLength: {
    displayName: 'lineLength',
  },
  typeDesc: {
    displayName: 'typeDesc',
  },
  typeName: {
    displayName: 'typeName',
  },
}
const config = {
  load: async function (filter) {
    const res = (await hillo.get('Printer.php', {
      op: 'showPrinterType',
      ...filter,
    }))
      .content

    // console.log('PrinterType', res)
    return res
  },
}

export default IKDataEntity.ModelFactory(entity, config)
