<template>
  <v-navigation-drawer
    v-model="realDialog"
    fixed
    :width="!$vuetify.breakpoint.mdAndUp ? '100%' : width"
    right
    touchless
    temporary
  >
    <v-container
      v-if="realDialog"
      class="pa-6"
      style="background-color: #F9FBFC"
    >
      <template v-if="!$vuetify.breakpoint.mdAndUp">
        <fab-button
          fab-fixed
          fab-bottom
          fab-right
          @click-event="save(); close();"
        />
      </template>
      <div class="d-flex align-center mb-8">
        <back-button @close-event="close"/>
        <div class="text-h3 font-weight-bold">
          {{ $t('device') }}/{{ printer.groupName }}
        </div>
        <v-spacer/>
      </div>
      <div>
        <div>
          <div class="text-h4 d-flex align-center font-weight-bold">
            {{ $t('ModifyLabelSize') }}
          </div>
          <div class="text-body-2 mt-2"/>
        </div>
        <div
          class="mt-4"
          style="display: grid;grid-template-columns: minmax(0,0.7fr) 1fr"
        >
          <div
            style="width: 100%"
            class="pr-8 py-4"
          >
            <div class="text-body-2">
              {{ $t('PleaseTakeCareOfMeasuresAlternativeScanQR') }}
            </div>
            <div class="pa-4">
              <v-img
                :src="require('@/assets/printers/123.png')"
              />
              <div class="text-caption">
                www.piliapp.com/actual-size/cm-ruler/
              </div>
            </div>
          </div>
          <div :class="!$vuetify.breakpoint.lgAndUp ? 'px-4' : 'px-6'">
            <div>
              <div>
                <div class="text-caption">
                  {{ $t('width') }}：
                </div>
                <v-text-field
                  v-model="localPrinter.width"
                  outlined
                  suffix="mm"
                />
              </div>

              <div>
                <div>
                  <div class="text-caption">
                    {{ $t('height') }}：
                  </div>
                  <v-text-field
                    v-model="localPrinter.height"
                    outlined
                    suffix="mm"
                  />
                </div>
                <div>
                  <div class="text-caption">
                    {{ $t('gap') }}：
                  </div>
                  <v-text-field
                    v-model="localPrinter.gap"
                    outlined
                    suffix="mm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-btn
            class="ml-n4"
            color="primary"
            text
            @click="showHint=true"
          >
            <v-icon left>
              mdi-help-circle-outline
            </v-icon>
            {{ $t('DontKnowHowToEnterTheSizeOfLabel') }}
          </v-btn>
          <v-spacer/>
          <v-btn
            outlined
            @click="testPrint(localPrinter.groupId[0], 3)"
          >
            <v-icon left>
              mdi-test-tube
            </v-icon>
            {{ $t('TestPrint') }}
          </v-btn>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-btn
              depressed
              class="primary"
              @click="save"
            >
              <v-icon left>
                mdi-content-save
              </v-icon>
              {{ $t('submit') }}
            </v-btn>
          </template>
        </v-card-actions>
      </div>
      <v-dialog
        v-model="showHint"
        max-width="600"
      >
        <v-card class="pa-4">
          <div class="text-h4">
            {{ $t('HowYouGetTheRightMeasure') }}
          </div>
          <div>
            <v-card
              flat
              color="#f6f6f6"
              class="pa-4 mt-4 d-flex"
            >
              <div style="width: 120px">
                <v-img
                  src="@/assets/printers/EtikettWidth.png"
                />
              </div>
              <div class="ml-4">
                <div class="text-body-1">
                  {{ $t('LabelSizeWidth') }}
                </div>
                <div class="text-body-2 mt-4">
                  {{ $t('LabelSizeWidthHint') }}
                </div>
              </div>
            </v-card>
            <v-card
              flat
              color="#f6f6f6"
              class="pa-4 mt-4 d-flex"
            >
              <div style="width: 120px">
                <v-img
                  src="@/assets/printers/EtikettHeight.png"
                />
              </div>
              <div class="ml-4">
                <div class="text-body-1">
                  {{ $t('LabelSizeHeight') }}
                </div>
                <div class="text-body-2 mt-4">
                  {{ $t('LabelSizeHeightHint') }}
                </div>
              </div>
            </v-card>
            <v-card
              flat
              color="#f6f6f6"
              class="pa-4 mt-4 d-flex"
            >
              <div style="width: 120px">
                <v-img
                  src="@/assets/printers/EtikettGap.png"
                />
              </div>
              <div class="ml-4">
                <div class="text-body-1">
                  {{ $t('IntervalBetweenLabels') }}
                </div>
                <div class="text-body-2 mt-4">
                  {{ $t('IntervalBetweenLabelsHint') }}
                </div>
              </div>
            </v-card>
            <v-btn
              depressed
              class="mt-4"
              color="primary"
              block
              @click="showHint=false"
            >
              {{ $t('confirm') }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
  import { printerTest } from '@/common/deviceTest'
  import IKUtils from 'innerken-js-utils'
  import Printer from '../../../../model/print/Printer'
  import BackButton from '@/composable/InK/BackButton'
  import FabButton from '@/views/AadenPages/components/FabButton/FabButton'

  export default {
    name: 'ChangeSizeAndTestDialog',
    components: { BackButton, FabButton },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
        default: 'min(800px, calc(100vw - 400px))',
      },
      printer: {
        type: Object,
        default: () => {
        },
      },
    },
    data () {
      return {
        realDialog: false,
        thermalWidth: 80,
        thermalPaperSize: ['80', '55', '40'],
        tab: null,
        localPrinter: { name: '' },
        showHint: false,

      }
    },
    watch: {
      value: {
        immediate: true,
        handler (val) {
          this.realDialog = val
          if (this.printer) {
            this.localPrinter = Object.assign(this.localPrinter, this.printer)
          }
        },
      },
      realDialog (val) {
        this.$emit('input', val)
      },

    },

    mounted () {

    },
    methods: {
      async testPrint (printGroupId, testCount) {
        await printerTest(printGroupId, testCount)
        IKUtils.toast()
      },
      async save () {
        const printer = Object.assign({}, this.localPrinter)
        await Printer.edit(printer, false)
        IKUtils.toast()
      },
      close () {
        this.$emit('need-refresh')
        this.realDialog = false
      },
    },
  }
</script>

<style scoped>

</style>
